'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import useUpdateUserDetails from '../data-fetch/update-user';
import { toast, ToastContainer } from 'react-toastify';
import { set } from 'date-fns';

export default function ResetPassword({open, setOpen, id}:{open:boolean, setOpen:any, id:string}) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableButton, setDisableButton] = useState(true)
  const [matchPattern, setMatchPattern] = useState(false);

  const { updateUser }  = useUpdateUserDetails()
  useEffect(()=>{
   const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
   if(password.match(passwordPattern)){
    setMatchPattern(true)
   } else {
    setMatchPattern(false)
   }

   if(password.match(passwordPattern) && password === confirmPassword ){
      setDisableButton(false)
   } else {
    setDisableButton(true)
   }
  },[password, confirmPassword])
  const handleSubmit = async (e:any)=>{
    const body = JSON.stringify({
      password
    })
    console.log(body)
    const updatePassword:any = await updateUser(id, body);
    if(updatePassword.success){
      toast( "Password Updated Succussfully.", {type:"success", theme:"colored"});
      setPassword("");
      setConfirmPassword("");
      
  } else {
      toast( updatePassword.error, {type:"error", theme:"colored"});
  }
  setOpen(false)
  
  }

  const handleCancel = ()=>{
    setPassword("");
    setConfirmPassword("");
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            
                </div> */}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Reset Password
                  </DialogTitle>
                  <div className="mt-2">
                    <form>
                    <div className="w-full  px-3 mb-6 md:mb-0 flex">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                          New Password
                        </label>
                        <input className="appearance-none block w-full bg-lightBlue text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="password" placeholder="Please Input password here" name='password' onChange={(e)=>{setPassword(e.target.value)}}/>
                      </div>
                          {  !matchPattern&&
                            <p className='text-sm text-red'>Password must contain at least 8 character including at least one number, one letter, and one special character</p>
                          }
                      <div className="w-full  px-3  mt-4 md:mb-0 flex">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                          Confirm Password
                        </label>
                        <input className="appearance-none block w-full bg-lightBlue text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-last-name" type="password" placeholder="Please Input password here" name='confirmPassword' onChange={(e)=>{setConfirmPassword(e.target.value)}}/>

                      </div>
                      { confirmPassword !== password  &&
                            <p className='text-sm text-red'>Password & confirm password must be same.</p>
                          }
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                disabled = {disableButton}
                onClick={(e) => handleSubmit(e)}
                className= {`inline-flex w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto ${ disableButton ? "opacity-50" :""} `}
              >
                Submit
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => handleCancel()}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-recity text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
      <ToastContainer />
    </Dialog>
  )
}
