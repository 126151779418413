import { useState } from "react";
// import ImageViwer from "../ImageViwer";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// import ScaleLoaderItem from "../../Ui-Elemnent/ScaleLoader";
import CircleLoderItem from "../../Ui-Elemnent/CircleLoader";
import useUserDetails from "../data-fetch/user-data-fetch";
import ResetPassword from "./ResetPassword";
import useUpdateUserDetails from "../data-fetch/update-user";
import useUserData from "../../../Data/userData";
export default function ViewUser() {
    const navigate = useNavigate();
    const params:any = useParams();
    const id:string = params.id || ""
    const {userDetails : loggedUserData} = useUserData();
    // console.log("params", id);
    const [passwordBoxOpen, setPasswordBoxOpen] = useState(false)
    const {userDetails, loading} = useUserDetails({id});
    const { updateUser }  = useUpdateUserDetails()
    console.log("userDetails", userDetails)
    let user:any = userDetails;
    // const [openImage, setOpenImage] = useState<boolean>(false);
    // const [imgUrl, setImgUrl] = useState<string>("")
    // const handleViewPictureButton = (e:any, imgUrl:any) =>{
    //   e?.preventDefault();
    //   if(imgUrl){
    //     if(imgUrl)
    //     typeof imgUrl === "string"  ? setImgUrl(imgUrl) : setImgUrl(imgUrl[0]);
      
    //     setOpenImage(true);
    //   }else{
    //     toast("Image is not available.", {type:"error", theme:"colored"})
    //   }

    // }
    const handleBack = (e:any)=>{
      e.preventDefault();
      navigate(-1)
    }

    const handlePaswordResetButton = (e:any) =>{
      e.preventDefault();
      setPasswordBoxOpen(true)
    }

    const handleUpdateUser = async (e:any)=>{
      e.preventDefault();
      console.log(e.target.name.value)
      const body = JSON.stringify({
        name: e.target.name.value,
        mobile:e.target.mobile.value,
        email: e.target.email.value,
        wardNumber: e.target.wardNumber.value,
        "additionalFields.typeOfJob ": e.target.typeOfJob.value,
        // "additionalFields.propertiesAllotted ": e.target.propertiesAllotted.value,
      })
      console.log(body)
      const updateUserInformation:any = await updateUser(id, body);
      if(updateUserInformation.success){
        toast( "User Information updated Succussfully.", {type:"success", theme:"colored"});
        

        
    } else {
        toast( updateUserInformation.error, {type:"error", theme:"colored"});
    }
    // setOpen(false)
    
    }
    return (
        <>
        {loading ? <CircleLoderItem loading={loading}/> : 
        
          <div className="m-20">
            
            <div className="p-y-20  rounded-md bg-slate flex justify-between">
              <div className="flex flex-row">
                <div className="m-2 uppercase font-semibold">user :</div>
                <div className="m-2 uppercase font-semibold">{user.username}</div>
              </div>
              <div className="flex flex-row">
                <div className="m-2 ">Created by :</div>
                <div className="m-2">{user.createdBy}</div>
              </div>
            </div>
            
          <form className="w-full mt-8" onSubmit={(e)=>{handleUpdateUser(e)}}>
  <div className="flex flex-wrap -mx-3 mb-6">
     
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
         name
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  name="name"  id="grid-last-name" type="text" placeholder="" value={user?.name}/>
    </div>
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        mobile no.
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"   id="grid-last-name" type="text" name="mobile" placeholder="" value={user?.mobile}/>
    </div>
    
  </div>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
       email id
      </label>
      <input className="appearance-none block w-full bg-lightBlue  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={user?.email}/>
    </div>  
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        country
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={user?.country}/>
    </div>
    
  </div>

  {/* <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        state
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={user?.state || "N A"}/>
    </div>
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
       city
      </label>
      <input className="appearance-none block w-full bg-recity text-white border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:text-black focus:bg-lightBlue" disabled id="grid-first-name" type="text" placeholder="" value={user?.city}/>
   
   </div>      
  </div> */}
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
       State
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={user?.state || "N A"}/>
    </div>  
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name" >
        City
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={user?.city || "N A"}/>
    </div>   
  </div>
  
  {/* <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
        Password
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-password" type="password" placeholder="******************" />
      <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p>
    </div>
  </div> */}
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
       Ward Number
      </label>
      <div className="relative">
        <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-state" name="wardNumber" value={user.wardNumber}>
          <option>{user.wardNumber}</option>
         
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> 
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        User Role
      </label>
      <input className="appearance-none block w-full bg-lightBlue uppercase text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" disabled id="grid-last-name" type="text" placeholder="" value={user?.userRole || "N A"}/>

    </div>
      <div className="w-full">

        <div className="w-full md:w-1/2 px-3 mt-4 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          {user?.additionalFields?.propertiesAllotted ? "Properties Alloted" : ""}

        </div>

        <div className="grid grid-cols-9 gap-2">
          {user?.additionalFields?.propertiesAllotted && user?.additionalFields?.propertiesAllotted.map((pt:any)=>{
            return <div className="border border-1 bg-highlighter text-xs">{pt.DDNNumber}</div>
          })}

        </div>
     
     </div>

     <div className="w-full">

        <div className="w-full md:w-1/2 px-3 mt-4 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          {user?.additionalFields?.clustersAllotted ? "cluster alloted" : ""}

        </div>

        <div className="grid grid-cols-9 gap-2">
          {user?.additionalFields?.clustersAllotted && user?.additionalFields?.clustersAllotted.map((pt:any)=>{
            return <div className="border border-1 bg-clusterBg">{pt}</div>
          })}

        </div>
     
     </div>
     { user?.additionalFields?.typeOfJob && 
     <div className="w-full md:w-1/2 px-3 my-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
       
        Type of Job
      </label>
      <div className="relative">
        <select className="block appearance-none w-full bg-lightBlue border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-state" name="typeOfJob" value={user?.additionalFields?.typeOfJob}>
          <option>{user?.additionalFields?.typeOfJob}</option>
         
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> }
       
  </div>
 <div className="flex justify-center">
        {(Number(loggedUserData.roleId) === 1 || Number(loggedUserData.roleId) === 2 ) && <button type="submit" className="bg-green p-4 m-4 text-white rounded uppercase">Update User</button>}
       {(Number(loggedUserData.roleId) === 1 || Number(loggedUserData.roleId) === 2 ) && <button className="bg-green p-4 m-4 text-white rounded uppercase" onClick={(e)=>{handlePaswordResetButton(e)}}>Reset Password</button>}
        <button className="bg-recity p-4 m-4 text-white rounded uppercase" onClick={(e)=>{handleBack(e)}}>Back</button>
 </div>
</form>
        </div>
        }
{/* {openImage && <ImageViwer setOpenImage={setOpenImage} imageUrl={imgUrl}/>}           */}
          <ToastContainer />
          {(Number(loggedUserData.roleId) === 1 || Number(loggedUserData.roleId) === 2) && 
          
          <ResetPassword open={passwordBoxOpen} setOpen={setPasswordBoxOpen} id={id} />
          }
        </> 
    );
  }


  