import React from "react";

const ToggleButton = ({ isOn, handleFunction, toggleData,  isDisabled }:{isOn:boolean, handleFunction:any, toggleData:any,  isDisabled:boolean }) => {
    const handleToggle = () => {
        handleFunction(toggleData); 
      };
  return (
    <div className="flex items-center justify-center">
      <button disabled={isDisabled}
        onClick={handleToggle} 
        className={`w-16 h-8 flex items-center rounded-full p-1 ${
          isOn ? "bg-green" : "bg-red"
        } transition duration-300`}
      >
        <div
          className={`w-6 h-6 bg-white rounded-full shadow-md transform ${
            isOn ? "translate-x-8" : "translate-x-0"
          } transition duration-300`}
        ></div>
      </button>
    </div>
  );
};

export default ToggleButton;
